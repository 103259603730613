import React from "react"
import Header from "../../templates/Header/index"
import MinimalFooter from "../../templates/MinimalFooter/index"

const OfferPage = () => {
  return (
    <>
      <Header />
      <div style={{ textAlign: "center" }}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScRxZDbGbsYPUk5QBPf7v0KIxKElJKmjEIr0sJvVtI3dXzo4Q/viewform?embedded=true"
          width="740"
          height="1259"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          style={{ marginTop: "100px" }}
        >
          Loading…
        </iframe>
      </div>

      <MinimalFooter />
    </>
  )
}

export default OfferPage
