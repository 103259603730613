

import React, { useEffect } from 'react';
import styled from 'styled-components';
import Cta from '../../atoms/CTA/index';
import './transparentHeader.scss';
import { StaticImage } from "gatsby-plugin-image"

const LogoContainer = styled.div`
    width: 17%;

    @media (max-width: 769px) {
        width: 30%;
    }
    @media (max-width: 500px) {
        width: 40%;
    }
`;

const TransparentHeaderTemplate = () => {
    useEffect(() => {
        // Anything in here is fired on component mount.
        window.addEventListener("scroll", handleScroll);
        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);

    function handleScroll() {
        if (window.scrollY > 20) {
            document.querySelector(".container").className = "container scroll";

        } else {
            document.querySelector(".container").className = "container";
        }
    };

    return (
        <div className="desktop-menu">
            <div className="container">
                <div className="header-container">
                    <LogoContainer href="/">
                        <StaticImage src="../../../resources/images/logos/Collexin-no-tag.png" alt="Collexin Logo"  placeholder="none" />
                    </LogoContainer>

                    <div className="login">
                        <a className="phone-number" href="tel:8883265539">
                        (888) 3-COLLEX (326-5539)
                        </a>

                        <Cta href="/contact-us">Contact Us</Cta>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TransparentHeaderTemplate;

