import styled from 'styled-components';
// import colours from '../../../helpers/colours';   ${colours.mvWhite}

const CTA = styled.a`
    background: #0CA14A;
    font-size: 15px;
    font-weight: bold;
    padding: 11px 23px;
    border-radius: ${props => props.borderRadius};
    color: white;
    cursor: pointer;
    border: none;
    text-decoration: none;
    transition: .3s;
    font-style: normal;

    &:hover {
        text-decoration: none;
        background: #00536E;
        transition: .4s;
        a {
            text-decoration: underline;
        }
    }

    a {
        text-decoration: none;
        color: white;
    }
`;

CTA.defaultProps = {
    borderRadius: '22px'
};

export default CTA;
